<template>
  <div class="zoom-wrap">
    <mts-textarea
      v-model="desc"
      label="Задать вопрос"
      placeholder="Введите вопрос"
      rows="2"
      style="width: 100%"
    />
    <br />
    <mts-button @click="submit" theme="dark" style="width: 130px">отправить</mts-button>
    <br />
    <mts-textarea
      v-if="res"
      v-model="res"
      label="Ответ"
      placeholder="описание"
      style="width: 100%"
      rows="8"
      readonly
    />
  </div>
</template>

<script>
import { postAxiosAI } from '@/views/ai/func'
export default {
  name: 'index-zoom-page',
  components: {},
  data() {
    return {
      desc: '',
      res: ''
    }
  },
  computed: {},
  methods: {
    async submit() {
      if (!this.desc) return
      const { msg } = await postAxiosAI(this.desc)
      if (msg) {
        this.res = msg.slice(1).slice(0, -1)
      }
    }
  },
  async created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
